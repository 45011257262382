import {$$} from '../utils';
import { chengeItemAmount } from '../basket'
import { changeProductPrice } from './';

const amountSwicher = (fieldSelector) => {
    const fields = $$(fieldSelector);

    if (fields.length === '0') return false;

    [...fields].forEach((field) => field.on('click', swichCounter));

}

const swichCounter = ({target}) => {
    if (target.classList.contains('btn') || target.tagName === 'path' || target.tagName === 'svg' || target.tagName === 'I') {
        const wrapper = target.closest('[data-element="field_number"]');
        const button = target.closest('.btn');
        const input = wrapper.querySelector('[data-element="input"]');
        
        let culc = Number(input.value) + Number(button.dataset.value);

        if (culc < input.min || culc > input.max) {
            return true
        }

        input.value = culc;
        wrapper.dataset.amount = culc
        
        if(wrapper.dataset.type === 'onPage'){
            changeProductPrice();
        } else {
            chengeItemAmount({target: input})
        }
    }
}

export default amountSwicher;