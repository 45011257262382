const domain = 'https://growbloom.ru/';
const init = {
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
    }
};

const getApiData = async ( path = '', options = init) =>
    fetch(domain + path, options)
        .then( response => response.json() )
        .catch( err => console.warn('Error on api fetching', err) );

export default getApiData;