import { $ } from './utils';
import { ymId } from './variables';
const matrikaReachGoals = () => {
    // Добавление товара в корзину на странице товара
    const addProductBtnOnProductPage = $('#buyBtn');
    const addProductBtnOnProductPageReachGoal = () => {
        window.ym(ymId, 'reachGoal', 'add-product');
    };
    addProductBtnOnProductPage && addProductBtnOnProductPage.on('click', addProductBtnOnProductPageReachGoal);
    // Добавление товара в корзину из каталога
    const cards = $('.cards');
    const cardsReachGoal = (e) => {
        if (e.target.classList.contains('add-product') && e.target.parentElement.classList.contains('add-product')) {
            window.ym(ymId, 'reachGoal', 'add-product');
        }
    };
    cards && cards.on('click', cardsReachGoal);
    // Клик "Оформить заказ"
    const purchaseBtn = $('.purchaseBtn');
    const purchaseBtnReachGoal = () => {
        window.ym(ymId, 'reachGoal', 'purchase');
    };
    purchaseBtn && purchaseBtn.on('click', purchaseBtnReachGoal);
    // Клик "Готово"
    const purchaseDoneBtn = $('.purchaseDone');
    const purchaseDoneBtnReachGoal = () => {
        window.ym(ymId, 'reachGoal', 'purchaseDone');
    };
    purchaseDoneBtn && purchaseDoneBtn.on('click', purchaseDoneBtnReachGoal);
};
export default matrikaReachGoals;
