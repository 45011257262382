const addToBasketFromCard = (btnSelector) => {
    const btns = document.querySelectorAll(btnSelector);
    if (btns.length == 0) {
        return false;
    }
    btns.forEach(btn => {
        const handler = (event) => {
            event.preventDefault();
            const id = btn.id;
            window.open(`/emarket/basket/put/element/${id}/`, '_self');
        };
        btn.addEventListener('click', handler);
    });
};
export default addToBasketFromCard;
