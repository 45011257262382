import {$$} from './utils';
// Переход на страницу по клику по карточке в любом месте кроме корзины
// Selector - контейнер в котором лежат карточки

const cardClick = (selector) => {
    const cards = $$(selector);
    if (!cards.length) return;
    
    [...cards].forEach((cardsWrapper) => {
        cardsWrapper.on('click', cardClickEvent);
    })
}

const cardClickEvent = ({ target }) => {
    if(!target.closest('[data-component="card"]')) return;

    const wrapper = target.closest('[data-component="card"]');
    const link = wrapper.querySelector('[data-component="card-link"]');

    link && link.click();
};

export default cardClick;