import { $ } from '../utils';
// const buyProductPage = () => {
//     const buyBtn = $('#buyBtn');
//     if (!buyBtn) return false;
//     buyBtn.addEventListener('click', buy)
// }
// const buy = (event) => {
//     event.preventDefault();
//     const baseLink = '/emarket/basket/put/element/';
//     const buyBtn = $('#buyBtn');
//     const prodId = buyBtn.dataset.prodid
//     const amount = $('[data-element="field_number"]').dataset.amount;
//     const props = $('[data-element="option_props"]');
//     const radios = props.querySelectorAll('input');
//     let propLink;
//     radios.forEach((radio) => {
//         if (radio.checked) {
//             console.log(radio);
//             propLink = `[${radio.name}]=${radio.id}`
//         }
//     })
//     const resultLink = `?amount=${amount}&options${propLink}`
//     event.target.href = resultLink
//     console.log(resultLink);
//     console.log(event);
//     event.target.click();
// }
const chengeBuyLink = () => {
    const baseLink = '/emarket/basket/put/element/';
    const buyBtn = $('#buyBtn');
    const prodId = buyBtn.dataset.prodid;
    const amount = $('[data-element="field_number"]').dataset.amount;
    const props = $('[data-element="option_props"]');
    const radios = props.querySelectorAll('input');
    let propLink;
    radios.forEach((radio) => {
        if (radio.checked) {
            console.log(radio);
            propLink = `options[${radio.name}]=${radio.id}`;
        }
    });
    const resultLink = `${baseLink}${prodId}/?amount=${amount}&${propLink}&`;
    buyBtn.pathname = resultLink;
};
export default chengeBuyLink;
