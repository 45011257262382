import cardClick from './cardClick';
import { putElement, chengeAmount } from './basket';
// import { mobileMenu } from './mobileMenu';
import { initFormValidate } from './forms';
import {productPrice, eMarketSaveInfo} from './eMarket';
import trunk from './trunk.ts';
import matrikaReachGoals from './matrikaReachGoals.ts';
import addToBasketFromCard from './eMarket/addToBasketFromCard.ts';
import fancy from './fancy.ts';

window.addEventListener('DOMContentLoaded', function(){

	matrikaReachGoals();
	eMarketSaveInfo('#eMarketSaveInfo', '#eMarketOrderBtn');
	addToBasketFromCard('.add-product');

	// Fancy
	const selectors = [
		{trigger: 'triggerEmailForm', form: 'email_form', header: 'Подписаться на рассылку'},
		{trigger: 'triggerConsultForm', form: 'default_form', header: 'Консультация', desc: 'Заполните данные формы и мы свяжемся с вами в ближайшее время'},
	];
	fancy(selectors);

// Переход на страницу по клику на карточку в любом месте кроме корзины
	cardClick('[data-component="cards"]');

//Мобильное меню
	// mobileMenu('[data-component="mobile_menu_trigger"]', '[data-component="mobile_menu"]');
// -------------------

	trunk();

// Опции и количество товара
// 1 - селектор кнопки "в корзину";
// 2 - селектор полей с опциями (input type=radio);
// 3 - cелектор поля для ввода количества товара (input);
	putElement('#put-btn', '.prop-option', '.good-count');
// -------------------

// Изменение количества товаров в корзине
// 1 - Обёртка корзины
	chengeAmount('#basket-cart');
// -------------------
 
// Валидация форм
	initFormValidate('.form', ['form_search', 'purchase-form'])
// -------------------
	
	productPrice();

// -------------------

});


// $(document).ready(function ($) {

// });